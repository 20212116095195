import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    color: "#572345",
    textDecoration: "none",
    marginBottom: 8
  }
}));

export default function Custom404() {
  const classes = useStyles();

  return (
    <main className={classes.main}>
      <div>
        <header>
          <Typography className={classes.title} variant="h3" component="h1">
            404 - Page Not Found
          </Typography>
        </header>
        <section>
          <Typography component="p">
            The page you are looking for cannot be found on this server.
          </Typography>
        </section>
      </div>
    </main>
  );
}
